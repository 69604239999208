
// This file contains the constants that are used in the app and tests.
// The constants are exported so that they can be used in other files.
// These may be changed based based on the requirements of the application.

// define the tooltips for the fields
const dateTooltip = "Accepted formats include: YYYY-MM-DD HH:mm:ss, DD/MM/YYYY HH:mm:ss, MM-DD-YYYY HH:mm, Excel numerical or PI style (e.g. *-1d). Hours and minutes are optional.";
const timeIntervalTooltip = "Accepted formats include: m, h, d e.g. 15m, 1h, 7d";
const tableTooltip = "The table name should be in the format 'businessunit.catalog.asset_datasecuritylevel_datatype'. Required for confidential tables.";
const outputCellTooltip = "This must include the sheet name";

// Export the constants used in the app and tests
module.exports = {
    APP_VERSION_NUMBER: "1.2",
    APP_DOMAIN_DEV: "localhost:3000",
    APP_DOMAIN_PROD: "exceladdin.rtdip.io",
    APP_REGISTRATION_ID_DEV: "bb6f6796-3da1-4f0f-9d12-ab9559058f22",
    APP_REGISTRATION_ID_PROD: "64acc73e-166b-4497-870c-6ec3777c9740",

    CUSTOM_FUNCTION_PARAMETERS: {
        "resample":                     {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["aggMethod", "includeBadData", "includeDateColumn", "refreshInterval"]},
        "interpolate":                  {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["aggMethod", "interpolationMethod", "includeBadData", "includeDateColumn", "refreshInterval"]},
        "interpolation_at_time":        {"required_params": ["apiUrl", "region", "tableName", "tagNames", "timestamps"], "advanced_params": ["windowLength", "includeBadData", "includeDateColumn", "refreshInterval"]},
        "raw":                          {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate"], "advanced_params": ["includeBadData", "refreshInterval"]},
        "time_weighted_average":        {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["step", "includeBadData",  "includeDateColumn", "refreshInterval"]},
        "circular_average":             {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval", "lowerBound", "upperBound"], "advanced_params": ["includeBadData",  "includeDateColumn", "refreshInterval"]},
        "circular_standard_deviation":  {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval", "lowerBound", "upperBound"], "advanced_params": ["includeBadData",  "includeDateColumn", "refreshInterval"]},
        "metadata":                     {"required_params": ["apiUrl", "region", "tableName", "tagNames"], "advanced_params": []},
        "latest":                       {"required_params": ["apiUrl", "region", "tableName", "tagNames"], "advanced_params": ["limit", "offset", "valueOnly", "refreshInterval"]},
        "summary":                      {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "summaryType"], "advanced_params": ["includeBadData", "refreshInterval"]},
        "sql":                          {"required_params": ["apiUrl", "region", "sqlQuery", "limit"], "advanced_params": ["specifiedWarehouse", "refreshIntervalSeconds"]}
    },
      
    CUSTOM_FUNCTION_NAME_MAPPING: {
        "timeweightedavg":          "time_weighted_average",
        "interpolate":              "interpolate",
        "interpolationattime":      "interpolation_at_time",
        "raw":                      "raw",
        "circularavg":              "circular_average",
        "circularstdev":            "circular_standard_deviation",
        "metadata":                 "metadata",
        "latest":                   "latest",
        "resample":                 "resample",
        "sql":                      "sql",
        "summary":                  "summary"
    },
      
    CUSTOM_FUNCTION_DEFAULT_PARAMS: {
        "aggMethod": "first",
        "includeBadData": true,
        "interpolationMethod": "forward_fill",
        "timestamps": [],
        "windowLength": 1,
        "step": true,
        "lowerBound": "",
        "upperBound": "",
        "outputCell": "Sheet1!A1",
        "includeDateColumn": true,
        "limit": 1000,
        "offset": 0,
        "valueOnly": false,
        "region": "EMEA",
        "summaryType": "Avg"
    },
  
    FIELD_INPUTS: {
        "tableName":           {label: "Table name (optional)", tooltip: tableTooltip, inputType: "text"},
        "tagNames":            {label: "Tag Names", inputType: "textArray"},
        "startDate":           {label: "Start Date", tooltip: dateTooltip, inputType: "text"},
        "endDate":             {label: "End Date", tooltip: dateTooltip, inputType: "text"},
        "timeInterval":        {label: "Time Interval", tooltip: timeIntervalTooltip, inputType: "text"},
        "aggMethod":           {label: "Aggregation Method", inputType: "select", options: ["first", "last", "avg", "min", "max"], uiOptions: ["First", "Last", "Avg", "Min", "Max"]},
        "includeBadData":      {label: "Include Bad Data", inputType: "select", options: [true, false], uiOptions: ["True", "False"]},
        "interpolationMethod": {label: "Interpolation Method", inputType: "select", options: ["forward_fill", "backward_fill", "linear"], uiOptions: ["Forward Fill", "Backward Fill", "Linear"]},
        "timestamps":          {label: "Timestamps", inputType: "textArray"},
        "windowLength":        {label: "Window Length", inputType: "text"},
        "step":                {label: "Step", inputType: "select", options: [true, false], uiOptions: ["True", "False"]},
        "lowerBound":          {label: "Lower Bound", inputType: "text"},
        "upperBound":          {label: "Upper Bound", inputType: "text"},
        "includeDateColumn":   {label: "Include Date Column", inputType: "select", options: [true, false], uiOptions: ["True", "False"]},
        "limit":               {label: "Limit", inputType: "text"},
        "offset":              {label: "Offset", inputType: "text"},
        "valueOnly":           {label: "Value Only", inputType: "select", options: [true, false], uiOptions: ["True", "False"]},
        "region":              {label: "Region", inputType: "select", options: ["EMEA", "Americas", "AsiaPac", "SX"]},
        "outputCell":          {label: "Output Cell", tooltip: outputCellTooltip, inputType: "text"},
        "summaryType":         {label: "Summary Type", inputType: "select", options: ["Avg", "Min", "Max", "Sum", "Count", "StDev", "Var"]},
        "refreshInterval":     {label: "Refresh Interval (s)", inputType: "text"}
    }
}