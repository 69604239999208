import React, {useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoopIcon from '@mui/icons-material/Loop';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchResults from './SearchResults';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import './TagSearchDialog.css';

/**
 * Represents a Tag Search Dialog component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The props object containing the component's properties.
 * @param {boolean} props.open - Determines whether the dialog is open or not.
 * @param {Array} props.currentTags - The array of currently selected tags.
 * @param {string} props.region - The region for the API.
 * @param {Function} props.handleTableCatalogQuery - The function to handle table catalog queries.
 * @param {Function} props.handleTagSearchQuery - The function to handle tag search queries.
 * @param {Function} props.onClose - The function to handle the dialog close event.
 * @param {Function} props.handleSave - The function to handle saving the selected tags.
 * @returns {JSX.Element} The TagSearchDialog component.
 */
function TagSearchDialog(props) {
    const [searchQuery, setSearchQuery] = useState("")
    const [catalog, setCatalog] = useState("")
    const [tablename, setTablename] = useState("")
    const [tablenameArr, setTablenameArr] = useState([])
    const [catalogArr, setCatalogArr] = useState([])
    const [selectedTags, setSelectedTags] = useState([...props.currentTags])
    const [searchResults, setSearchResults] = useState([]);
    const [page, setPage] = useState(1);
    const [moreResultsAvail, setMoreResultsAvail] = useState(true)
    const [initResultsMessage, setInitResultsMessage] = useState("Try search for a tag or description")
    const [error, setError] = useState({occured: false, message: ""})
    const [loading, setLoading] = useState(false)
    const [switchAlignment, setSwitchAlignment] = useState("search-results")

    const isInitialMount = useRef(true);


    // On initial load, get the list of catalogs
    useEffect(() => {
        const getCatalogs = async () => {
            let data = await props.handleTableCatalogQuery({catalogName: "", schemaName: "", region: props.region})
                // filter out unwanted catalogs
            const to_exclude = ["__databricks_internal", "data_monitoring", "system", "samples", "main"]
            data = data.filter(catalog => !to_exclude.includes(catalog))
            if(data.length == 0){
                setError({occured: true, message: "No catalogs found. There may be an issue with the connection to the database, please check your API url."})
            }
            setCatalogArr(data)
        }
        getCatalogs()
    }, [])

    // If the catalog changes, get the list of table names
    useEffect(() => {
        if (catalog !== "" && catalog !== undefined) {
            const getTableNames = async () => {
                const data = await props.handleTableCatalogQuery({catalogName: catalog, schemaName: "sensors", region: props.region, "metadataQuery": true})
                setTablenameArr(data)
            }
        getTableNames()
        }
    }
    , [catalog])


    // If the page number changes, get the next set of results
    useEffect(() => {
        if (isInitialMount.current) {
            // Check if this is the initial mount, if so, do not run the code below 
            isInitialMount.current = false;
        } else if (page > 1){
            setLoading(true)
            const fetchData = async () => {
                try {
                    const data = await props.handleTagSearchQuery({"catalog": catalog, "search_query": searchQuery, "page_number": page, region: props.region, tablename: tablename})
                    if(data.length < 10) {
                        setMoreResultsAvail(false)
                    }
                    setSearchResults([...searchResults, ...data])
                } catch (error) {
                    console.log(error)
                }
                setLoading(false)
            }
            fetchData();
        }
    }, [page]);


    const handleClose = () => {
        props.onClose();
    };

    const handleSwitchChange = (event, newAlignment) => {
        setSwitchAlignment(newAlignment);
    };

    const handleSearchClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        setPage(1)
        setMoreResultsAvail(true)
        setSearchResults([])

        try {
            // if tablename selected, then create a full table name else null
            let fullTableName = tablename ? `${catalog}.sensors.${tablename}` : null

            // Get the first set of results
            const data = await props.handleTagSearchQuery({"catalog": catalog, "search_query": searchQuery, "page_number": page, region: props.region, tablename: fullTableName})
            if(data.length < 10) {
                setMoreResultsAvail(false)
            }
            setSearchResults([...data])
        } catch (error) {
            console.log(error)
        }
        
        setLoading(false)

        // After first load then set the message
        setInitResultsMessage("No tags matching the criteria were found.")
    }

    const handleSearchMore = async () => {
        setPage(prevPage => prevPage + 1)
    }


    const handleTagToggle = (item) => {

        if (selectedTags.includes(item)) {
          // If the item is already in the array, remove it
          setSelectedTags(selectedTags.filter(tag => tag !== item));
        } else {
          // If the item is not in the array, add it
          setSelectedTags([...selectedTags, item]);
        }
      };
      

    const handleSave = () => {
        props.handleSave(selectedTags)
        handleClose()
    }


    const fontStyle = {style: {fontSize: 14}}

return (
    <Dialog fullScreen onClose={handleClose} open={props.open} className='tagsearch-dialog'>

        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
        <CloseIcon />    
        </IconButton>
    
        <DialogContent dividers className='tagsearch-dialog-content' 
            sx={{
                marginTop: "50px",
                height: '95%',
              }}>

            
            <form style={{display: "flex", flexDirection: "column"}}>

                <FormControl sx={{height: "100%", width: "100%", marginBottom: "10px"}}>
                    <InputLabel sx={{fontSize: 14}} id="catalog-select-label">Catalog</InputLabel>
                    <Select
                        labelId="catalog-select-label"
                        label="Catalog"
                        id="catalog-select"
                        key="catalog-select"
                        value={catalog}
                        onChange={(e) => setCatalog(e.target.value)}
                        sx={{
                            fontSize: 14,
                            "&.Mui-disabled": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)"
                            }
                        }}
                        fullWidth
                        disabled = {catalogArr.length == 0 ? true : false}
                    >
                        {catalogArr.map((item, idx) => 
                            <MenuItem key={idx} value={item} sx={{fontSize: 14}}>{item}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <FormControl sx={{height: "100%", width: "100%", marginBottom: "10px"}}>
                    <InputLabel sx={{fontSize: 14}} id="tablename-select-label">Table Name (optional)</InputLabel>
                    <Select
                        labelId="tablename-select-label"
                        label="Tablename"
                        id="tablename-select"
                        key="tablename-select"
                        value={tablename}
                        onChange={(e) => setTablename(e.target.value)}
                        sx={{
                            fontSize: 14,
                            "&.Mui-disabled": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)"
                            }
                        }}
                        fullWidth
                        disabled = {tablenameArr.length == 0 ? true : false}
                    >
                        {tablenameArr.map((item, idx) => 
                            <MenuItem key={idx} value={item} sx={{fontSize: 14}}>{item}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <div style={{display: "flex", flexDirection: "row", height: "50px"}}>

                <TextField 
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    label="Search"
                    key="search"
                    sx={{fontSize: 12, width: "100%", marginRight: "5px", marginBottom: "10px"}}
                    value={searchQuery}
                    placeholder="Type something..."
                    onChange={(event) => setSearchQuery(event.target.value)}
                    fullWidth
                />

                <Tooltip
                    title={
                        catalog === "" ? "Please select a catalog" : ""}
                >
                    <span>
                    <Button 
                        type="submit" 
                        sx={{
                            minWidth: "0px",
                            width: "50px",
                            height: "100%",
                            backgroundColor: "blueviolet",
                            '&:hover': {
                                backgroundColor: 'rgba(137, 43, 226, 0.822)',
                            },
                        }} 
                        variant="contained" 
                        aria-label="search-button" 
                        label="search-button"
                        onClick={ (e) => handleSearchClick(e) }
                        disabled={loading || catalog == "" ? true : false}
                    >   
                        {loading ? <LoopIcon className='input-icon'/> :  <SearchIcon />}
                    </Button>
                    </span>
                </Tooltip>

                </div>

            </form>

            
            <ToggleButtonGroup
                color="primary"
                value={switchAlignment}
                exclusive
                onChange={handleSwitchChange}
                size="small"
                sx={{ 
                marginBottom: '20px',
                marginTop: '20px',
                display: "flex",
                justifyContent: "center"
                }}
            >
                <ToggleButton color="info" value="search-results">Search Results</ToggleButton>
                <ToggleButton value="manage-tags">Manage Tags</ToggleButton>
            </ToggleButtonGroup>
            
            <div className='tagsearch-results-container'>
                {switchAlignment == "search-results" ?
                    <div className='tagsearch-results'>
                        <SearchResults 
                            results={searchResults}
                            handleTagToggle={handleTagToggle}
                            selectedTags={selectedTags}
                            initResultsMessage={initResultsMessage}
                        />
                        {searchResults.length > 0 && moreResultsAvail &&
                            <Button 
                                type="button" 
                                aria-label="request-more" 
                                label="request-more"
                                onClick={ handleSearchMore }
                                disabled={loading ? true : false}
                            >
                                {loading ? <LoopIcon className='input-icon'/> :  "More"}
                            </Button>
                        }
                    </div>

                    :
                    <SearchResults 
                        results={props.currentTags.map(element => [element, ""]) }
                        handleTagToggle={handleTagToggle}
                        selectedTags={selectedTags}
                    />
                }
            </div>

            {error.occured && 
            <Alert sx={{ marginTop: '10px' }} severity="error">
              {error.message}
            </Alert>
            }

        </DialogContent>

        <DialogActions>
          <Button 
            onClick={handleSave}
            sx={{
                backgroundColor: "blueviolet",
                color: "white",
                '&:hover': {
                    backgroundColor: 'rgba(137, 43, 226, 0.822)',
                }
            }} 
            >
            Save
          </Button>
      </DialogActions>

    </Dialog>
);
}


export default TagSearchDialog;