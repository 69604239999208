import React, { useState, useEffect} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from "@mui/material";
import { addClickHandlers, removeClickHandler} from '../../utils/ExcelUtils'
import TableSelector from '../TableSelector/TableSelector';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';
import './RawQueryForm.css';

/**
 * RawQueryForm component for executing raw SQL queries.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The component props.
 * @param {Object} props.sqlFormData - The form data for SQL query.
 * @param {string} props.sqlFormData.sqlQuery - The SQL query string.
 * @param {string} props.sqlFormData.region - The region for executing the query.
 * @param {string} props.sqlFormData.limit - The limit for the query results.
 * @param {string} props.sqlFormData.outputCell - The output cell for the query results.
 * @param {boolean} props.sqlFormData.specifiedWarehouse - Flag indicating whether to use specified SQL Warehouse.
 * @param {Function} props.handleError - The error handling function.
 * @param {Function} props.handleClick - The click handling function.
 * @returns {JSX.Element} The RawQueryForm component.
 * 
 * @example
 * return <RawQueryForm sqlFormData={sqlFormData} handleError={handleError} handleClick={handleClick}/>
 */
function RawQueryForm(props) {
    const [sqlQuery, setsqlQuery] = useState("");
    const [outputCell, setOutputCell] = useState("Sheet1!A1");
    const [limit, setLimit] = useState("1000");
    const [specifiedWarehouse, setSpecifiedWarehouse] = useState(props.settings && props.settings["httpPath"] ? true : false);
    const [activeInput, setActiveInput] = useState(null)
    const [statefulEventResults, setStatefulEventResults] = useState([])
    const [cellClickSeed, setCellClickSeed] = useState(0)
    const [selectedCell, setSelectedCell] = useState(null)
    const [region, setRegion] = useState("EMEA")

    useEffect(() => {
        
        if (Object.keys(props.sqlFormData).length > 0) {

            setsqlQuery(props.sqlFormData["sqlQuery"])
            setRegion(props.sqlFormData["region"])
            setLimit(props.sqlFormData["limit"])
            setOutputCell(props.sqlFormData["outputCell"])

            // Specified warehouse is an optional value
            if (props.sqlFormData["specifiedWarehouse"]) {
                setSpecifiedWarehouse(Boolean(props.sqlFormData["specifiedWarehouse"]))
            }
        }
      }, [props.formData]);

      useEffect(() => {
        // If on sql form, check the hostname and http_path are 
        const hostname = props.settings && props.settings["hostname"]
        const httpPath = props.settings && props.settings["httpPath"]
        const hasRequiredSetings = hostname && httpPath

        if(!hasRequiredSetings && specifiedWarehouse){
            props.handleError("Please set the Hostname and HTTP Path in the settings, or uncheck the 'Use specified SQL Warehouse' checkbox.")
        } else {
            props.handleError("reset")
        }
      }
      , [specifiedWarehouse])


      useEffect(() => { 

        const clickHandlerEffect = async () => {
            // Add click handler to excel on mount so that we can get the selected cell
            let callback = (cellAddress) => {
                setSelectedCell(cellAddress)
                setCellClickSeed((prev) => prev + 1)
            }

            let eventResults = await addClickHandlers(callback)
            setStatefulEventResults(eventResults)
        }

        clickHandlerEffect()

        // Define cleanup function for component unmount
        const cleanup = async () => {
            if(statefulEventResults.length > 0){
                statefulEventResults.forEach(async statefulEventResult => {
                    try {
                        await removeClickHandler(statefulEventResult)
                    } catch (error) {
                        console.error(error)
                    }
                })
            }
            setStatefulEventResults([])
        }

        return cleanup
    }, [])


    // Add body click listener to remove active input setting
    const handleBodyClick = (event) => {
        if(
            event.target.classList.contains("MuiInputBase-input") 
            || 
            event.target.classList.contains("MuiTextField-root")
            ||
            event.target.classList.contains("npm__react-simple-code-editor__textarea")
        ){
            return
        }
        setActiveInput(null)
    };
    
      useEffect(() => {
        
        document.body.addEventListener('click', handleBodyClick);
    
        // Cleanup function to remove the event listener
        return () => {
          document.body.removeEventListener('click', handleBodyClick);
        };
      }, []);


    useEffect( () => { 

        // If a cell is selected and an input is active, set the value of the input to the selected cell
        if(selectedCell != null && activeInput != null){
            if(activeInput === "outputCell"){
                setOutputCell(selectedCell)
            }

            if(activeInput === "sqlQuery"){
                setsqlQuery(selectedCell)
            }
            
        }

        setActiveInput(null)
    }, [cellClickSeed])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isNaN(limit) || limit < 0 || limit > 10000000 || limit === "") {
            props.handleError("Limit must be a number between 0 and 10000000")
        } else if (sqlQuery === ""){
            props.handleError("SQL Query cannot be empty")
        } else {
            let paramsToSend = {
                "mode": "raw-query",
                "query_type": "sql",
                "region": region,
                "sqlQuery": sqlQuery.replaceAll("\"", "'"),
                "limit": limit,
                "specifiedWarehouse": specifiedWarehouse,
                "outputCell": outputCell
            }

            const parameterObjLists = {
                "required_params": ["apiUrl", "region", "sqlQuery", "limit"],
                "advanced_params": ["specifiedWarehouse", "refreshIntervalSeconds"],
            }
 
            props.handleClick(paramsToSend, parameterObjLists);
        }
      
    }

    // Handle input focus
    const handleInputClick = async (param) => {
        setActiveInput(param)
    };

    // handle checkbox change
    const handleCheckboxChange = (e) => {
        if(e.target.checked){
            setSpecifiedWarehouse(true)
        } else {
            setSpecifiedWarehouse(false)
        }
    }

    const handleUpdateTableName = (val) => {
        const newQuery = `SELECT * FROM ${val}`
        setsqlQuery(newQuery)
    }

    const fontStyle = {style: {fontSize: 14}}

    return (
        <form onSubmit={handleSubmit} data-testid="sql-query-form">
    
            <div className="input-container" >

                
                <FormControlLabel 
                    control={
                        <Checkbox size="small" checked={specifiedWarehouse} onChange={(e) => {handleCheckboxChange(e)}}/>
                    }
                    
                    label={<Typography sx={{fontSize: 12}}>Use specified SQL Warehouse (settings)</Typography>}
                />

                <FormControl fullWidth size="small" margin="dense" key={"region-select"}>
                    <InputLabel id="region-select-label">Region</InputLabel>
                    <Select
                        labelId="region-select-label"
                        id="region-select"
                        value={region}
                        label="Region"
                        onChange={(event) => setRegion(event.target.value)}
                        sx={{fontSize: 14}}
                    >
                        <MenuItem value={"EMEA"}        sx={{fontSize: 14}}>EMEA</MenuItem>
                        <MenuItem value={"AsiaPac"}     sx={{fontSize: 14}}>AsiaPac</MenuItem>
                        <MenuItem value={"Americas"}    sx={{fontSize: 14}}>Americas</MenuItem>
                    </Select>
                </FormControl>

                <TableSelector 
                    handleTableCatalogQuery={(parameters) => {
                        return props.handleTableCatalogQuery({
                            ...parameters,
                            specifiedWarehouse: specifiedWarehouse
                        })
                    }}
                    updateTableName={handleUpdateTableName}
                    handleError={props.handleError}
                    region={region}
                    specifiedWarehouse={specifiedWarehouse}
                />
                
                <div className='sql-editor-container-wrapper'>
                    <div className='sql-editor-container' onClick={(e) => {handleInputClick("sqlQuery")}}>
                        <Editor
                            label="SQL Query"
                            textareaId="SQL Query"
                            placeholder='Type a SQL Query...'
                            value={sqlQuery}
                            onValueChange={code => {
                                if(code.length > 255){
                                    props.handleError("SQL Query is greater than 255 characters. Please copy the query into a cell and reference it.")
                                } else {
                                    props.handleError("reset")
                                }
                                setsqlQuery(code)
                            }}
                            highlight={code => highlight(code, languages.sql)}
                            padding={10}
                            className='sql-editor'
                        />
                    </div>
                </div>


                <TextField  
                    label="Limit"
                    size="small" margin="dense"
                    fullWidth={true}
                    multiline
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    value={limit}
                    error={isNaN(limit) || limit < 0 || limit > 10000000}
                    onChange={(event) => setLimit(event.target.value)}
                />
                <TextField 
                    label="Output cell"
                    size="small"
                    margin="dense"
                    fullWidth={true}
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    value={outputCell}
                    onFocus={(e) => handleInputClick("outputCell")}
                    onMouseDown={(e) => handleInputClick("outputCell")}
                    focused={activeInput == "outputCell"}
                    onChange={(event) => setOutputCell(event.target.value)}
                />

            </div>

            <div className="button-container">
                <Button 
                    endDecorator={<KeyboardArrowRight />}
                    color="info" 
                    type="submit"
                    disabled={sqlQuery.length === 0 || sqlQuery.length > 255}
                >
                    Run
                </Button>
            </div>

        </form>
    )
}


export default RawQueryForm;
