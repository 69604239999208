import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import './TagSearchDialog.css';


/**
 * Renders the search results component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The component props.
 * @param {Array} props.results - The search results.
 * @param {Function} props.handleTagToggle - The function to handle tag toggle.
 * @param {Array} props.selectedTags - The selected tags.
 * @param {string} props.initResultsMessage - The initial results message.
 * @returns {JSX.Element} The rendered search results component.
 */
function SearchResults(props){

    const handleTooltipClick = (e, item) => {
        e.stopPropagation();
        navigator.clipboard.writeText(item);
    }

    return (
        <div className='searchresults'>
            {props.results.length > 0 ?
                props.results.map((result, idx) =>
                    <button 
                        className='searchresults-result' 
                        key={idx} 
                        onClick={(e) => props.handleTagToggle(result[0])}
                    > 
                        <div className='searchresults-result-content'>
                        {result.map( (item, idx) => {
                            if(idx == 0){
                                // Tagname
                                return (
                                    <Tooltip 
                                        key={idx}
                                        placement='top-start'
                                        title={
                                            <div 
                                                className='searchresults-tooltip'
                                                onClick={(e) => {handleTooltipClick(e, item)}}
                                            >
                                                Copy to clipboard
                                            </div>
                                        }
                                    >
                                        <p className='searchresults-result-tagname' key={idx}>{item}</p>
                                    </Tooltip>
                                )
                            } else if(idx == 1){
                                // Description
                                return <p className='searchresults-result-description' key={idx}>{item}</p>
                            } else if(idx == 2){
                                // Table name
                                return <p className='searchresults-result-tablename' key={idx}>{item}</p>
                            }
                        })}
                        </div>

                        <div className='searchresults-check'>
                            {props.selectedTags.includes(result[0]) && <DoneIcon />}                            
                        </div>

                    </button>
                    
                )
                :
                <p className="searchresults-message">{props.initResultsMessage ? props.initResultsMessage : ""}</p>
            }      
        </div>
        
        
    )
}


export default SearchResults;